
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        































































































.sudpresse {
  ::v-deep .hero__slides__item__content {
    max-width: 70rem;
  }
}

.sudpresse__myvoo {
  ::v-deep figure {
    width: 100%;
  }

  ::v-deep img {
    width: auto;
    max-height: 65rem;
    margin: 0 auto;
  }
}

.sudpresse__entertainment {
  ::v-deep img {
    width: auto;
    max-height: 60rem;
    margin: 0 auto;
  }
}
